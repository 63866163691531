import isNil from 'lodash/isNil';
import { useCallback } from 'react';

import { useCommerce } from '@commerce';
import type { AddItemHook } from '@commerce/types/cart';
import { CommerceError } from '@commerce/utils/errors';
import type { MutationHook } from '@commerce/utils/types';
import { useMutationHook } from '@commerce/utils/use-hook';
import { LAST_ADDED_SKU_LOCAL_STORAGE, PREMIUM_SOURCE_SESSION_STORAGE } from '@framework/const';
import { addQueryString } from '@framework/lib/add-query-string';
import { setStorage } from '@lib/browser-storage';
import { pushAddToCartEvent } from '@lib/gtag';

import useCart from './use-cart';

export const handler: MutationHook<AddItemHook> = {
  fetchOptions: {
    url: '/api/cart',
    method: 'POST',
  },
  async fetcher({ input, options, fetch }) {
    const { locale, ...item } = input;

    if (!item.productId) {
      throw new CommerceError({
        message: 'The item ID has to be valid',
      });
    }

    if (!isNil(item.quantity) && (!Number.isInteger(item.quantity) || item.quantity! < 1)) {
      throw new CommerceError({
        message: 'The item quantity has to be a valid integer greater than 0',
      });
    }

    const url = addQueryString(options.url!, { locale });

    try {
      const data = await fetch({
        ...options,
        url: url.pathname + url.search,
        body: { item },
      });

      return data;
    } catch (err: any) {
      if (err?.status === 404) {
        // returns empty data
        // cart mutate will refetch/reload the cart
        return { cart: null };
      }

      throw err;
    }
  },
  useHook: ({ fetch }) =>
    function useCustomHook() {
      const { mutate } = useCart();
      const { locale } = useCommerce();

      return useCallback(
        async (input) => {
          const fetchInput = { ...input, locale };
          const data = await fetch({ input: fetchInput });

          if (window) {
            if (fetchInput.premiumSource) {
              setStorage(`${PREMIUM_SOURCE_SESSION_STORAGE}_${locale}`, {
                category: fetchInput.premiumSource,
                url: window.location.pathname,
              });
            }

            const skuForOverlay = data.lastAddedSKU || input?.sku;
            if (skuForOverlay) {
              setStorage(`${LAST_ADDED_SKU_LOCAL_STORAGE}_${locale}`, skuForOverlay, { session: false });
            }
          }
          pushAddToCartEvent(fetchInput, data.cart);

          await mutate(data.cart, false);
          return data;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetch, mutate]
      );
    },
};

export type UseAddItem<H extends MutationHook<AddItemHook<any>> = MutationHook<AddItemHook>> = ReturnType<H['useHook']>;

const useAddItem: UseAddItem = (...args) => {
  return useMutationHook({ ...handler })(...args);
};

export default useAddItem as UseAddItem<typeof handler>;
